import { httpRequest, TRANS_CODE_DATA } from "@/util/common";
import React, { Component } from "react";
import { connect } from "react-redux";
import WishList from "./WishList";
import Swal from "sweetalert2";
import { clientLogout } from "@/commonSlices/authSlice";

class WishListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wishLists: [],
      chosen: {},
    };
  }

  componentDidMount() {
    this.fetchWishLists();
  }

  render() {
    const { wishLists, chosen } = this.state;
    return (
      <WishList
        wishLists={wishLists}
        chosen={chosen}
        onCheckbox={this.handleCheckbox}
        onCheckboxAll={this.handleCheckboxAll}
        onDeleteByIds={this.handleDeleteByIds}
        onOrder={this.handleOrder}
      />
    );
  }

  /**
   * 서버에서 wishList 정보 받아오기
   */
  fetchWishLists = async () => {
    const { user } = this.props.auth;
    const { history } = this.props;
    try {
      const { data: wishLists } = await httpRequest.get(`cart/${user.idx}`);
      this.setState({ wishLists, chosen: {} });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      history.push(`/error?code=${err.response.status}`);
    } finally {
      // 다건주문을 단건주문으로 변경하며 체크박스를 라이오버튼으로 변경했는데 삭제해도 라디오버튼이 체크상태로 남는 문제 초기화 -> 추후 다건주문 변경 시 삭제
      const $radioBtns = document.querySelectorAll(".wishResult");
      if ($radioBtns && $radioBtns.length > 0) {
        $radioBtns.forEach((btn) => {
          btn.checked = false;
        });
      }
    }
  };

  /**
   * 보관함 리스트 중 체크박스 선택하기
   * @param {*} post
   */
  handleCheckbox = (post) => {
    const chosen = {};

    chosen[post.wishIdx] = {
      charCount: post.charCount,
      documentDate: post.documentDate,
      documentId: post.documentId,
      documentNo: post.documentNo,
      documentName: post.documentName,
      orgLang: post.orgLang,
      transLang: post.transLang,
      transCoverage: post.transCoverage,
      costUnit: TRANS_CODE_DATA[post.orgLang + post.transLang]["unitCost"],
      transType: post.transType,
      chargePoint: post.chargePoint,
    };
    this.setState({ chosen });
  };

  /**
   * 체크박스 전체 선택하기 -> 추후 다건 주문으로 수정 시 필요함.
   * @param {*} e
   */
  handleCheckboxAll = (e) => {
    const value = e.target.checked;
    let chosen = {};
    if (value) {
      chosen = this.state.wishLists.reduce((a, c) => {
        a[c.wishIdx] = {
          charCount: c.charCount,
          documentDate: c.documentDate,
          documentId: c.documentId,
          documentNo: c.documentNo,
          documentName: c.documentName,
          orgLang: c.orgLang,
          transLang: c.transLang,
          transCoverage: c.transCoverage,
          costUnit: TRANS_CODE_DATA[c.orgLang + c.transLang]["unitCost"],
          transType: c.transType,
          chargePoint: c.chargePoint,
        };
        return a;
      }, {});
    }

    this.setState({ chosen });
  };

  /**
   * 선택한 목록 삭제하기
   */
  handleDeleteByIds = () => {
    Swal.fire({
      title: "삭제하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        httpRequest
          .delete(`cart`, { data: Object.keys(this.state.chosen) })
          .then((res) => {
            this.fetchWishLists();
            Swal.fire("삭제되었습니다.");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "보관함 삭제 실패",
            });
          });
      }
    });
  };

  /**
   * 주문 페이지로 이동하기
   * @param {*} previewDocs
   */
  handleOrder = () => {
    const { chosen } = this.state;
    const { transType, orgLang, transLang } = Object.values(chosen)[0];

    if (transType !== 'AA' && !this.checkKoEn(orgLang, transLang)) {
      alert('선택하신 기능은 지원되지 않아 보관함에서 삭제됩니다. 이용에 불편을 드려 죄송합니다.');

      httpRequest
        .delete(`cart`, { data: Object.keys(chosen) })
        .then((res) => {
          this.fetchWishLists();
        });

      return;
    }

    this.props.history.push({
      pathname: "/payment/orderPayment",
      query: {
        chosen,
        transReqType: "P",
      },
    });
  };

  checkKoEn(orgLang, transLang) {
    if ((orgLang === 'KO' && transLang === 'EN') || (orgLang === 'EN' && transLang === 'KO')) {
      return true;
    } else {
      return false;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { clientLogout })(WishListContainer);
