import React, { useEffect } from 'react';
import {Link} from "react-router-dom";
import './Home.scss';
import {autoWindowScroll} from "@/util/common";

const Home = (props) => {
  useEffect(() => {
    document.body.style.overflowX = 'hidden';
  }, []);
  return (
    <div id="contentsWrapper" className="home">
      <div className="msec-01 active section" id="section-1">
        <div className="visual">
          <div className="txt">
            <p className="btxt">지금 당장 번역문이 필요하다면? <br/>번역의 품질도 놓칠 수 없다면?</p>
            <p className="stxt">특허에 관한 문서 번역은</p>
            <p className="ctxt">특허 전문 AI 번역 <span><strong>IP KINGKONG</strong>으로 시작하세요.</span></p>
            {/*<a id="ipkingkongInfo" className="sec-btn" style={{ cursor: 'pointer' }} onClick={autoWindowScroll.bind(this, 'section-2')}><span>IP킹콩은?</span></a>*/}
          </div>
          <div className="img">
            <p className="robot"><img src="/images/main/about-img01.png" alt=""/></p>
            <p className="page"><img src="/images/main/about-img02.png" alt=""/></p>
          </div>
        </div>
        <ul>
          <li className="">
            <Link to={'/search/patentPublication'} style={{ textDecoration: 'none' }}>
              <p className="btxt">특허공보 번역</p>
              <p className="stxt">특허공보 번역이 필요하세요? <span className="break">특허번호만 있으면 충분합니다.</span> <span
                  className="block">IP킹콩은 각 국가별 특허데이터베이스를 보유하고 있습니다.</span></p>
            </Link>
          </li>
          <li>
            <Link to={'/translation/myDoc/uploader'} style={{ textDecoration: 'none' }}>
              <p className="btxt">내 문서 번역</p>
              <p className="stxt">보유하고 있는 특허문서 파일에 대한 번역이 필요하세요? <span className="break">특허문서 파일(Microsoft Office)에 대한 번역서비스를</span><span
                  className="block">IP킹콩은 제공하고 있습니다.</span></p>
            </Link>
          </li>
        </ul>
      </div>
      <div className="msec-02 active section" id="section-2">
        <h3>IP KINGKONG은?</h3>
        <p className="stit">IP KINGKONG은 새로운 특허 번역 패러다임을 제시하는 <span
            className="cblue"><strong>AI 번역 플랫폼</strong></span>으로, <span className="block">보다 신속하고 정확하고 경제적인 번역 서비스를 통해 특허업무 생산성을 향상해드립니다.</span>
        </p>
        <ul className="info">
          <li>
            <p className="btxt">특허 전문 AI 번역</p>
            <p className="stxt">특허 문서를 학습하여 <span>더 똑똑한 특허 전문 AI 번역</span></p>
          </li>
          <li>
            <p className="btxt">휴먼 번역 검수</p>
            <p className="stxt">특허 번역 노하우를 보유한<span>번역전문가의 휴먼 검수</span></p>
          </li>
        </ul>
        <ul className="guide">
          <li>
            <p className="btxt">단 24시간만에 <span>번역문이 눈앞에</span></p>
            <p className="stxt">필요한 특허번역 결과를 <span>단 24시간(최소 6시간) 안에 </span>받을 수 있습니다.</p>
          </li>
          <li>
            <p className="btxt">휴먼번역가의 <span>90% 수준의 고품질 번역</span></p>
            <p className="stxt">최첨단 신경망번역 시스템과 <span>휴먼보정을 통한 고품질의 </span>번역 결과를 제공해드립니다.</p>
          </li>
          <li>
            <p className="btxt">휴먼번역 대비 <span>80% 비용 절감</span></p>
            <p className="stxt">특허 전문 인공신경망 <span>NMT번역으로 휴먼번역보다</span>비용을 절약하실 수 있습니다.</p>
          </li>
          <li>
            <p className="btxt">쉽고 간단하게 <span>번역 신청 절차</span></p>
            <p className="stxt">특허번호를 바로 검색하거나 <span>문서 파일을 업로드하여 쉽게 </span>번역을 신청할 수 있습니다.</p>
          </li>
        </ul>
      </div>
      <div className="msec-03 active section" id="section-3">
        <h3>IP KINGKONG이 특별한 이유</h3>
        <ul>
          <li>
            <p className="btxt">더 <span>싸다</span></p>
            <p className="stxt">신경망번역 + 휴먼검수 = 84,000원<span className="block">(단어당 14원)</span><span className="ex">* 휴먼검수 : 문장 누락 체크</span>
            </p>
            <p className="ctxt">휴먼번역 비용 = 450,000원<span className="block">(단어당 75원)</span></p>
          </li>
          <li>
            <p className="btxt">더 <span>빠르다</span></p>
            <p className="stxt">최소 6시간에서 <span className="block">최대 24시간 이내 납품</span></p>
            <p className="ctxt">최소 7일 정도의 <span className="block">번역기간 소요</span></p>
          </li>
          <li>
            <p className="btxt">더 <span>정확하다</span></p>
            <p className="stxt">동일한 <span className="block">번역 퀄리티 유지</span></p>
            <p className="ctxt">번역가 경력에 따라 <span className="block">번역 퀄리티 상이</span></p>
          </li>
          <li>
            <p className="btxt">더 <span>편하다</span></p>
            <p className="stxt">특허번호 입력이나 문서 파일 <span className="block">업로드만으로 번역 신청 가능</span></p>
            <p className="ctxt">
              번역용 특허공보 파일의 고객 제공 필요
              <span className="block">메일을 통한 복잡한 견적 확인/번역 의뢰 절차</span>번역비용 외 문서 편집 비용 별도
            </p>
          </li>
        </ul>
        <p className="rtxt">* 영문 특허공보(1페이지당 300단어 기준, 20장)을 한국어 번역 의뢰시</p>
      </div>
      <div className="msec-04 active section" id="section-4">
        <h3>IP KINGKONG 이용방법</h3>
        <ul className="use-step">
          <li style={{ height: 400 }}>
            <div>
              <p className="btxt">특허번호 검색 또는 문서 업로드</p>
              <p className="stxt">특허번호를 검색하거나 번역할 특허 문서를 업로드하세요.</p>
              <p className="img"><img src="/images/main/use-step01.jpg" alt=""/></p>
            </div>
          </li>
          <li style={{ height: 400 }}>
            <div>
              <p className="btxt">번역 퀄리티 선택</p>
              <p className="stxt">필요한 문서에 따라 번역 퀄리티를 선택하세요.</p>
              <dl className="c-01">
                <dt><p><span>BAsic</span></p></dt>
                <dd>AI 번역</dd>
                <dd>기본휴먼 보정</dd>
              </dl>
              <dl className="c-02">
                <dt><p><span>SPECIAL</span></p></dt>
                <dd>Basic내용</dd>
                <dd><span><strong>번역전문가 리뷰</strong></span></dd>
              </dl>
            </div>
          </li>
          <li style={{ height: 400 }}>
            <div>
              <p className="btxt">예상 금액 확인 및 번역 의뢰</p>
              <p className="stxt">번역 비용을 바로 확인후, 번역을 의뢰하세요.</p>
              <p className="img"><img src="/images/main/use-step03.jpg" alt=""/></p>
            </div>
          </li>
          <li style={{ height: 400 }}>
            <div>
              <p className="btxt">번역문 확인</p>
              <p className="stxt">번역 완료 메일이 오면 My번역에서 번역문을 확인하세요.</p>
              <p className="img"><img src="/images/main/use-step04.jpg" alt="번역이 완료되었습니다!"/></p>
            </div>
          </li>
        </ul>
      </div>
      <div className="msec-05 active section" id="section-5">
        <h3>IP KINGKONG을 만든 사람들</h3>
        <ul>
          <li>
            <p className="logo"><img src="/images/main/made-logo01.png" alt=""/></p>
            <p className="btxt">50년의 역사, 인공신경망 기계번역 전문기업 SYSTRAN</p>
            <p className="stxt">
              기계번역기술의 글로벌 리더인 SYSTRAN은 1968년 설립이후, 기계 번역시장을 개척해
              <span className="break">왔습니다. 현재 국가기관, 기업, 개인 소비자 등을 통해 사용되는 기계번역 서비스의 주제공자로서, 한국특허청에 특허전문기계번역 솔루션을 제공하고 있습니다.특허 분야에 특화된</span>
              <span className="break">혁신적인 인공신경망 기계번역기술을 통하여, 고객에게 높은 업무 생산성을 제공합니다.</span>
            </p>
            <a href="http://www.systransoft.com/" target="_blank" className="more"><span>시스트란 둘러보기</span></a>
          </li>
          <li>
            <p className="logo"><img src="/images/main/logo-vertical-color-black.png" height="49" alt=""/></p>
            <p className="btxt">AI 특허검색엔진 키워트, 워트인텔리전스</p>
            <p className="stxt">워트인텔리전스는 세상을 볼 수 있는 key가 특허정보 안에 있다고 믿습니다. 유관 분야<span className="break"> 석·박사 연구진 및 변리사 연구진으로 구성된 특허 연구센터를 운영하고 있으며, 수년간 </span>연구해
              온 특허에 최적화된 데이터수집, 검색, 텍스트 마이닝, 빅데이터 처리, AI 알고리즘 <span className="break">등의 기술과 특허 데이터베이스를 보유한 특허 전문기업입니다.</span>
            </p>
            <a href="https://www.wertcorp.com/kr/main" target="_blank" className="more"><span>워트인텔리전스 둘러보기</span></a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Home;
