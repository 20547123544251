import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import Privacy from "../Privacy/Privacy";
import Policy from "../Policy/Policy";

const Footer = ({
  showPrivacy,
  showPolicy,
  togglePrivacy,
  togglePolicy,
  onClickIntroLink,
  onClickPriceLink
}) => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <div className="footer-contents">
        <ul className="footer-contents__link">
          <li>
            <Link to="#" onClick={onClickIntroLink}>
              <span>IP KINGKONG 소개</span>
            </Link>
          </li>
          <li>
            <Link to="/board/news">
              <span>도움말</span>
            </Link>
          </li>
          <li>
            <Link to="#" onClick={onClickPriceLink}>
              <span>이용요금</span>
            </Link>
          </li>
        </ul>
        <ul className="footer-contents__popup">
          <li>
            <Link to="#" onClick={togglePrivacy}>
              <span>개인정보처리방침</span>
            </Link>
            <Privacy showPrivacy={showPrivacy} togglePrivacy={togglePrivacy} />
          </li>
          <li>
            <Link to="#" onClick={togglePolicy}>
              <span>이용약관</span>
            </Link>
            <Policy showPolicy={showPolicy} togglePolicy={togglePolicy} />
          </li>
        </ul>
        <div className="footer-contents__company">
          <p className="name">
            (주)워트인텔리전스 <span>사업자등록번호 675-86-00133</span>
          </p>
          <p className="addr">
            서울시 서초구 강남대로 507, 6층 대표 윤정호
            <span>통신판매업신고 2019-서울서초-1670</span>
          </p>
          <p>문의 02-521-0110 (ARS 3번) / cs@ipkingkong.com (평일 09:00 ~ 18:00)</p>
          <p>
            Copyright © { year } IP KINGKONG (WERT Intelligence x SYSTRAN).
            <span>All rights reserved.</span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
