import React, { Component, Fragment } from 'react';
import './css/common.scss';
import './css/colors.css';
import './css/index.scss';
import './css/typography-ltp.scss';
import './css/typography-mbl.scss';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import { Pagination } from 'swiper';
import { Link } from 'react-router-dom';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import './css/accordion.scss';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.isAuthenticated
	}
}

@connect(mapStateToProps, null)
export default class Service extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			mode: null
		}
		
		this.setMode = this.setMode.bind(this);
		
		window.addEventListener('resize', this.setMode);
	}
	
	componentDidMount() {
		this.setMode();
	}
	
	setMode() {
		if( window.innerWidth <= 768 ) {
			this.setState({
				mode: 'sm'
			})
		} else {
			this.setState({
				mode: 'lg'
			})
		}
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.setMode);
	}
	
	render() {
		if( !this.state.mode ) {
			return null;
		}
		return (
			<div className="service-info-page">
				<section id='section-hero'>
					<div className='container hero-section'>
						<div className={ `title-container${this.props.isAuthenticated ? ' title-container-auth' : ''}` }>
							<div className='title-box'>
								<h2 className='title display-sm'>특허전문 <span className='lb-mbl'>AI 번역 서비스</span><span className='lb-all'>IP KINGKONG</span></h2>
								<p className='description body-lg'>차별화된 가격과 품질의 새로운 <span className='lb-mbl'>번역 서비스를 만나세요.</span></p>
							</div>
							<ul className='item-container'>
								<li className='item'>
									<h3 className='item-title title-sm'>최대 80%</h3>
									<p className='item-description body-xs'>시중 번역업체보다 <br/>저렴한 비용</p>
								</li>
								<li className='item'>
									<h3 className='item-title title-sm'>24시간</h3>
									<p className='item-description body-xs'>6~24시간 내 번역 <br/>(basic 상품 기준)</p>
								</li>
								<li className='item'>
									<h3 className='item-title title-sm'>보안강화</h3>
									<p className='item-description body-xs'>플랫폼 번역으로 <br/>안전한 번역보안</p>
								</li>
							</ul>
							<div className='event-container'>
								<div className="download-button card-title-sm">
									<a href="https://drive.google.com/file/d/1fbyR4TK2lxf_ytNzx0dftMOQmY5BNdab/view" target="_blank">소개자료 다운로드</a>
								</div>
							</div>
						</div>
						<div className='visual-container'>
							<div className='img-box'>
								<img src='/images/service/hero-visual.png' alt='특허전문 AI 번역 서비스 IP KINGKONG'/>
							</div>
						</div>
					</div>
				</section>
				<section id="section-intro">
					<div className="container intro-section">
						<div className="title-container">
							<div className="title-box">
								<h2 className="title title-md"><span className="lb-all">저렴한 가격, </span><span className="lb-mbl">고품질 번역을 </span>찾고 계신가요?</h2>
								<p className="description body-lg">IP킹콩의 IP번역은 다릅니다.</p>
							</div>
						</div>
						<div className="item-container">
							<ul className="item-wrapper">
								<li className="item">
									<div className="item-header">
										<div className="img-box">
											<img src="/images/service/icons/intro-icon-1.png" alt="저렴하면서도 빠른 번역속도"/>
										</div>
										<div className="item-title--mbl card-title-sm">저렴하면서도 빠른 번역속도</div>
									</div>
									<div className="item-text">
										<h3 className="item-title card-title-sm">저렴하면서도 빠른 번역속도</h3>
										<p className="item-description body-md">필요한 번역문을 단 24시간 <br/>(최소 6시간, basic 상품기준) <br/>안에 받으실 수 있습니다.</p>
									</div>
								</li>
								<li className="item">
									<div className="item-header">
										<div className="img-box">
											<img src="/images/service/icons/intro-icon-2.png" alt="특허만 학습한 고품질 번역"/>
										</div>
										<div className="item-title--mbl card-title-sm">특허만 학습한 고품질 번역</div>
									</div>
									<div className="item-text">
										<h3 className="item-title card-title-sm">특허만 학습한 고품질 번역</h3>
										<p className="item-description body-md">특허 전문 인공신경망 <br/>NMT 번역과 휴먼보정을 통한 <br/>고품질 번역을 제공합니다.</p>
									</div>
								</li>
								<li className="item">
									<div className="item-header">
										<div className="img-box">
											<img src="/images/service/icons/intro-icon-3.png" alt="번역업체 대비 50~80% 저렴"/>
										</div>
										<div className="item-title--mbl card-title-sm">번역업체 대비 50~80% 저렴</div>
									</div>
									<div className="item-text">
										<h3 className="item-title card-title-sm">번역업체 대비 50~80% 저렴</h3>
										<p className="item-description body-md">시중 업체 대비 최대 80%까지 <br/>저렴한 비용으로 특허 명세서 번역을 <br/>의뢰할 수 있습니다.</p>
									</div>
								</li>
							</ul>
						</div>
						{
							this.state.mode === 'lg' ? (
								<div className="item-card-container">
									<ul className="item-card-wrapper">
										<li className="item">
											<div className="item-header">
												<div className="img-box">
													<img src="/images/service/icons/intro-icon-4.png" alt="인커밍 / 아웃고잉을 위한 빠른 번역"/>
												</div>
											</div>
											<div className="item-text">
												<h3 className="item-title card-title-md">인커밍 / 아웃고잉을 위한 <br/>빠른 번역</h3>
												<p className="item-description body-lg">출원서류를 준비할 때에는 빠르게 검토할 수 있도록 제대로 번역된 초안이 필요합니다.
													IP킹콩에서 번역을 진행하시면 특허에 특화된 AI번역엔진이 특허에 적합한 단어를 사용해 번역을 제공합니다.</p>
											</div>
										</li>
										<li className="item">
											<div className="item-header">
												<div className="img-box">
													<img src="/images/service/icons/intro-icon-5.png" alt="IDS / 우선권 주장을 위한 번역문 제출시"/>
												</div>
											</div>
											<div className="item-text">
												<h3 className="item-title card-title-md">IDS / 우선권 주장을 위한 <br/>번역문 제출시</h3>
												<p className="item-description body-lg">수많은 IDS 문헌과 번역문을 준비할 때는 예상을 뛰어넘는 시간과 비용이 필요합니다.
													IP킹콩으로 시간과 비용을 획기적으로 절감해보세요.</p>
											</div>
										</li>
										<li className="item">
											<div className="item-header">
												<div className="img-box">
													<img src="/images/service/icons/intro-icon-6.png" alt="빠르고 좋은 번역이 필요할 때"/>
												</div>
											</div>
											<div className="item-text">
												<h3 className="item-title card-title-md">빠르고 좋은 번역이 <br/>필요할 때</h3>
												<p className="item-description body-lg">공개되지 않은 문헌의 번역이 필요할 때, 보안에 더욱 신경쓰고 싶을 때 IP킹콩을 이용해
													보세요. 플랫폼을 통해 번역되기 때문에 메일을 통한 보안이슈에도 안전합니다.</p>
											</div>
										</li>
									</ul>
									<div className="pagination-container"></div>
								</div>
							) : (
								<Swiper
									slidesPerView={1.25}
									spaceBetween={20}
									centeredSlides={!0}
									modules={[Pagination]}
									pagination={{ el: '#section-intro .pagination-container' }}
									className="item-card-container"
									tag="div"
									wrapperTag="ul"
								>
									<SwiperSlide className="item" tag="li">
										<div className="item-header">
											<div className="img-box">
												<img src="/images/service/icons/intro-icon-4.png" alt="인커밍 / 아웃고잉을 위한 빠른 번역"/>
											</div>
										</div>
										<div className="item-text">
											<h3 className="item-title card-title-md">인커밍 / 아웃고잉을 위한 <br/>빠른 번역</h3>
											<p className="item-description body-lg">출원서류를 준비할 때에는 빠르게 검토할 수 있도록 제대로 번역된 초안이 필요합니다.
												IP킹콩에서 번역을 진행하시면 특허에 특화된 AI번역엔진이 특허에 적합한 단어를 사용해 번역을 제공합니다.</p>
										</div>
									</SwiperSlide>
									<SwiperSlide className="item" tag="li">
										<div className="item-header">
											<div className="img-box">
												<img src="/images/service/icons/intro-icon-5.png" alt="IDS / 우선권 주장을 위한 번역문 제출시"/>
											</div>
										</div>
										<div className="item-text">
											<h3 className="item-title card-title-md">IDS / 우선권 주장을 위한 <br/>번역문 제출시</h3>
											<p className="item-description body-lg">수많은 IDS 문헌과 번역문을 준비할 때는 예상을 뛰어넘는 시간과 비용이 필요합니다.
												IP킹콩으로 시간과 비용을 획기적으로 절감해보세요.</p>
										</div>
									</SwiperSlide>
									<SwiperSlide className="item" tag="li">
										<div className="item-header">
											<div className="img-box">
												<img src="/images/service/icons/intro-icon-6.png" alt="빠르고 좋은 번역이 필요할 때"/>
											</div>
										</div>
										<div className="item-text">
											<h3 className="item-title card-title-md">빠르고 좋은 번역이 <br/>필요할 때</h3>
											<p className="item-description body-lg">공개되지 않은 문헌의 번역이 필요할 때, 보안에 더욱 신경쓰고 싶을 때 IP킹콩을 이용해
												보세요. 플랫폼을 통해 번역되기 때문에 메일을 통한 보안이슈에도 안전합니다.</p>
										</div>
									</SwiperSlide>
									<div className="pagination-container"></div>
								</Swiper>
							)
						}
					</div>
				</section>
				<section id="section-features">
					<div className="container features-section">
						<div className="title-container">
							<div className="title-box">
								<h2 className="title title-md">IP킹콩을 <span className="lb-mbl">써야하는 이유</span></h2>
								<p className="description body-lg">BLEU는 수번역문과 기계번역문의 정확도를 정량적 지수로 표기한 점수 입니다. <br/><br/>기계번역문이
									수번역문의 n-gram이 얼마나 매칭되는지 측정하고, 이들이 유사할수록 100점에 가까운 점수를 획득합니다. <br/><br/>IP KINGKONG의 번역엔진은
									BLEU평가와 자체적으로 진행한 휴먼평가에서 타 AI 번역기 대비 우수한 성능을 인정받았습니다.</p>
							</div>
						</div>
						<ul className="item-container">
							<li className="item">
								<div className="img-box">
									<img src="/images/service/icons/feat-machine--ltp.png" alt="기계평가(BLEU)"/>
								</div>
							</li>
							<li className="item">
								<div className="img-box">
									<img src="/images/service/icons/feat-human--ltp.png" alt="휴먼평가"/>
								</div>
							</li>
						</ul>
					</div>
				</section>
				<section id="section-cost">
					<div className="container cost-section">
						<div className="title-container">
							<div className="title-box">
								<h2 className="title title-md">상품별 번역 단가</h2>
							</div>
						</div>
						{
							this.state.mode === 'lg' ? (
								<div className="table-container">
									<div className="table-header">
										<div className="item-header blank"></div>
										<div className="item-header feature-a body-md">특허전문 AI 번역</div>
										<div className="item-header feature-b body-md">휴먼 기본보정</div>
										<div className="item-header option-a body-md">번역 전문가 리뷰</div>
										<div className="item-header ko-to-en body-md">한→영 번역</div>
										<div className="item-header en-to-ko body-md">영→한 번역</div>
										<div className="item-header ko-to-ch body-md">한→중 번역</div>
										<div className="item-header ch-to-ko body-md">중→한 번역</div>
										<div className="item-header ko-to-jp body-md">한→일 번역</div>
										<div className="item-header jp-to-ko body-md">일→한 번역</div>
									</div>
									<div className="table-wrapper">
										<div className="table-data">
											<div className="item-data data-header card-title-sm">BASIC</div>
											<div className="item-wrapper">
												<div className="item-title body-md">특허전문 AI 번역</div>
												<div className="item-data data-feature-a">
													<div className="img-box"><img src="/images/service/icons/check.png" alt="특허전문 AI 번역"/></div>
												</div>
											</div>
											<div className="item-wrapper">
												<div className="item-title body-md">휴먼 기본보정</div>
												<div className="item-data data-feature-b">
													<div className="img-box"><img src="/images/service/icons/check.png" alt="휴먼 기본보정"/></div>
												</div>
											</div>
											<div className="item-wrapper">
												<div className="item-title body-md">번역 전문가 리뷰</div>
												<div className="item-data data-option-a">
													<div className="img-box"><img src="/images/service/icons/uncheck.png" alt="번역 전문가 리뷰"/></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md">한→영 번역</div>
													<div className="item-data data-ko-to-en card-title-md">8.2 <span
														className="sub-text body-md">캐시/문자</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md">영→한 번역</div>
													<div className="item-data data-en-to-ko card-title-md">14 <span
														className="sub-text body-md">캐시/단어</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md">한→중 번역</div>
													<div className="item-data data-ko-to-ch card-title-md">10 <span
														className="sub-text body-md">캐시/문자</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md">중→한 번역</div>
													<div className="item-data data-ch-to-ko card-title-md">8 <span className="sub-text body-md">캐시/문자</span>
													</div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md">한→일 번역</div>
													<div className="item-data data-ko-to-jp card-title-md">6 <span className="sub-text body-md">캐시/문자</span>
													</div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md">일→한 번역</div>
													<div className="item-data data-jp-to-ko card-title-md">5 <span className="sub-text body-md">캐시/문자</span>
													</div>
												</div>
											</div>
										</div>
										<div className="table-data">
											<div className="item-data data-header card-title-sm">SPECIAL</div>
											<div className="item-wrapper">
												<div className="item-title body-md">특허전문 AI 번역</div>
												<div className="item-data data-feature-a">
													<div className="img-box"><img src="/images/service/icons/check.png" alt="특허전문 AI 번역"/></div>
												</div>
											</div>
											<div className="item-wrapper">
												<div className="item-title body-md">휴먼 기본보정</div>
												<div className="item-data data-feature-b">
													<div className="img-box"><img src="/images/service/icons/check.png" alt="휴먼 기본보정"/></div>
												</div>
											</div>
											<div className="item-wrapper">
												<div className="item-title body-md">번역 전문가 리뷰</div>
												<div className="item-data data-option-a">
													<div className="img-box"><img src="/images/service/icons/check.png" alt="번역 전문가 리뷰"/></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md">한→영 번역</div>
													<div className="item-data data-ko-to-en card-title-md">28.2 <span className="sub-text body-md">캐시/문자</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md">영→한 번역</div>
													<div className="item-data data-en-to-ko card-title-md">56 <span className="sub-text body-md">캐시/단어</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md">한→중 번역</div>
													<div className="item-data data-ko-to-ch card-title-md">-</div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md">중→한 번역</div>
													<div className="item-data data-ch-to-ko card-title-md">-</div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md">한→일 번역</div>
													<div className="item-data data-ko-to-jp card-title-md">-</div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md">일→한 번역</div>
													<div className="item-data data-jp-to-ko card-title-md">-</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								<Swiper
									slidesPerView={1.05}
									spaceBetween={20}
									modules={[Pagination]}
									pagination={{ el: '#section-cost .pagination-container' }}
									className="table-container"
									tag="div"
									wrapperTag="div"
								>
									<SwiperSlide className="table-data" tag="div">
										<div className="item-data data-header card-title-sm">BASIC</div>
										<div className="item-wrapper">
											<div className="item-title body-md">특허전문 AI 번역</div>
											<div className="item-data data-feature-a">
												<div className="img-box"><img src="/images/service/icons/check.png" alt="특허전문 AI 번역"/></div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-title body-md">휴먼 기본보정</div>
											<div className="item-data data-feature-b">
												<div className="img-box"><img src="/images/service/icons/check.png" alt="휴먼 기본보정"/></div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-title body-md">번역 전문가 리뷰</div>
											<div className="item-data data-option-a">
												<div className="img-box"><img src="/images/service/icons/uncheck.png" alt="번역 전문가 리뷰"/></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md">한→영 번역</div>
												<div className="item-data data-ko-to-en card-title-md">8.2 <span
													className="sub-text body-md">캐시/문자</span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md">영→한 번역</div>
												<div className="item-data data-en-to-ko card-title-md">14 <span
													className="sub-text body-md">캐시/문자</span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md">한→중 번역</div>
												<div className="item-data data-ko-to-ch card-title-md">10 <span
													className="sub-text body-md">캐시/문자</span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md">중→한 번역</div>
												<div className="item-data data-ch-to-ko card-title-md">8 <span className="sub-text body-md">캐시/문자</span>
												</div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md">한→일 번역</div>
												<div className="item-data data-ko-to-jp card-title-md">6 <span className="sub-text body-md">캐시/문자</span>
												</div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md">일→한 번역</div>
												<div className="item-data data-jp-to-ko card-title-md">5 <span className="sub-text body-md">캐시/문자</span>
												</div>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide className="table-data" tag="div">
										<div className="item-data data-header card-title-sm">SPECIAL</div>
										<div className="item-wrapper">
											<div className="item-title body-md">특허전문 AI 번역</div>
											<div className="item-data data-feature-a">
												<div className="img-box"><img src="/images/service/icons/check.png" alt="특허전문 AI 번역"/></div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-title body-md">휴먼 기본보정</div>
											<div className="item-data data-feature-b">
												<div className="img-box"><img src="/images/service/icons/check.png" alt="휴먼 기본보정"/></div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-title body-md">번역 전문가 리뷰</div>
											<div className="item-data data-option-a">
												<div className="img-box"><img src="/images/service/icons/check.png" alt="번역 전문가 리뷰"/></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md">한→영 번역</div>
												<div className="item-data data-ko-to-en card-title-md">28.2 <span className="sub-text body-md">캐시/문자</span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md">영→한 번역</div>
												<div className="item-data data-en-to-ko card-title-md">56 <span className="sub-text body-md">캐시/문자</span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md">한→중 번역</div>
												<div className="item-data data-ko-to-ch card-title-md">-</div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md">중→한 번역</div>
												<div className="item-data data-ch-to-ko card-title-md">-</div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md">한→일 번역</div>
												<div className="item-data data-ko-to-jp card-title-md">-</div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md">일→한 번역</div>
												<div className="item-data data-jp-to-ko card-title-md">-</div>
											</div>
										</div>
									</SwiperSlide>
									<div className="pagination-container"></div>
								</Swiper>
							)
						}
					</div>
				</section>
				<section id="section-compare">
					<div className="container compare-section">
						<div className="title-container">
							<div className="title-box">
								<h2 className="title title-md">번역 비용 비교</h2>
								<p className="description body-lg">시중 번역업체 단가를 적용했을 때 {this.state.mode === 'sm' && <br/>}IP KINGKONG과 번역비용 비교입니다</p>
							</div>
						</div>
						{
							this.state.mode === 'lg' ? (
								<div className="item-container slide-container">
									<div className="table-header">
										<h3 className="item-header card-title-sm"></h3>
										<h3 className="item-header card-title-sm">기준 단어</h3>
										<h3 className="item-header card-title-sm">시중 번역업체</h3>
										<h3 className="item-header card-title-sm item-active">IP 킹콩</h3>
									</div>
									<div className="table-data">
										<div className="data">
											<p className="data-title body-md">
												<span className="data-type">한→</span>
												<span className="data-type">영 번역</span>
											</p>
										</div>
										<div className="data description-box">
											<p className="data-description body-md">14,465 단어 기준</p>
											<div className="data-summary body-sm">(12pt / 631 line / 25 page)</div>
										</div>
										<div className="data">
											<p className="data-price-other card-title-sm">50~60<span className="body-md">만원</span></p>
										</div>
										<div className="data">
											<p className="data-price-our card-title-md">약 15<span className="body-lg">만원</span></p>
										</div>
									</div>
									<div className="table-data">
										<div className="data">
											<p className="data-title body-md"><span className="data-type">영→</span><span className="data-type">한 번역</span></p>
										</div>
										<div className="data description-box">
											<p className="data-description body-md">14,146 단어 기준</p>
											<div className="data-summary body-sm">(12pt / 1,630 line / 60 page)</div>
										</div>
										<div className="data">
											<p className="data-price-other card-title-sm">100~140<span className="body-md">만원</span></p>
										</div>
										<div className="data">
											<p className="data-price-our card-title-md">약 20<span className="body-lg">만원</span></p>
										</div>
									</div>
									<div className="table-data">
										<div className="data">
											<p className="data-title body-md"><span className="data-type">한→</span><span className="data-type">중 번역</span></p>
										</div>
										<div className="data description-box">
											<p className="data-description body-md">14,465 단어 기준</p>
											<div className="data-summary body-sm">(12pt / 631 line / 25 page)</div>
										</div>
										<div className="data">
											<p className="data-price-other card-title-sm">35~45<span className="body-md">만원</span></p>
										</div>
										<div className="data">
											<p className="data-price-our card-title-md">약 11<span className="body-lg">만원</span></p>
										</div>
									</div>
									<div className="table-data">
										<div className="data">
											<p className="data-title body-md"><span className="data-type">중→</span><span className="data-type">한 번역</span></p>
										</div>
										<div className="data description-box">
											<p className="data-description body-md">16,814 단어 기준</p>
											<div className="data-summary body-sm">(12pt / 600 line / 34 page)</div>
										</div>
										<div className="data">
											<p className="data-price-other card-title-sm">40~50<span className="body-md">만원</span></p>
										</div>
										<div className="data">
											<p className="data-price-our card-title-md">약 9<span className="body-lg">만원</span></p>
										</div>
									</div>
									<div className="table-data">
										<div className="data">
											<p className="data-title body-md"><span className="data-type">한→</span><span className="data-type">일 번역</span></p>
										</div>
										<div className="data description-box">
											<p className="data-description body-md">14,465 단어 기준</p>
											<div className="data-summary body-sm">(12pt / 631 line / 25 page)</div>
										</div>
										<div className="data">
											<p className="data-price-other card-title-sm">70~80<span className="body-md">만원</span></p>
										</div>
										<div className="data">
											<p className="data-price-our card-title-md">약 18<span className="body-lg">만원</span></p>
										</div>
									</div>
									<div className="table-data">
										<div className="data">
											<p className="data-title body-md"><span className="data-type">일→</span><span className="data-type">한 번역</span></p>
										</div>
										<div className="data description-box">
											<p className="data-description body-md">8,406 단어 기준</p>
											<div className="data-summary body-sm">(12pt / 631 line / 25 page)</div>
										</div>
										<div className="data">
											<p className="data-price-other card-title-sm">40~50<span className="body-md">만원</span></p>
										</div>
										<div className="data">
											<p className="data-price-our card-title-md">약 9<span className="body-lg">만원</span></p>
										</div>
									</div>
									<p className="item-description-notice body-md">*1페이지당 비용(K-editor 기준,바탕체 12pt, 페이지당 20line)<br/>한→영 : 약 6,000원<br/>한→중 : 약 7,500원<br/>한→일 : 약 4,500원</p>{/* MOBILE ITEM STRUCTURE */}
								</div>
							) : (
								<Swiper
									slidesPerView={1.05}
									spaceBetween={20}
									modules={[Pagination]}
									pagination={{ el: '#section-compare .pagination-container' }}
									className="item-container slide-container"
									tag="div"
									wrapperTag="div"
								>
									<SwiperSlide className="item" tag="div">
										<div className="item-wrapper">
											<div className="item-header">
												<h3 className="item-title body-lg"><span className="data-type">한</span><span className="data-type">→영 번역</span>
												</h3>
												<div className="item-description-box">
													<p className="item-description">14,465 단어 기준</p><span className="item-summary">(12pt / 631 line / 25 page)</span>
												</div>
											</div>
											<div className="item-content-box">
												<div className="item-content left-content">
													<span className="label body-sm">시중업체</span><span className="other-price">50~60</span><span className="body-sm">만원</span>
												</div>
												<div className="img-box">
													<img src="/images/service/icons/arrow-lg.png" alt="arrow"/>
												</div>
												<div className="item-content right-content">
													<span className="label body-lg">IP 킹콩</span>
													<span className="our-price body-lg">약 15</span>
													<span className="tag body-sm">만원</span>
												</div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-header">
												<h3 className="item-title body-lg"><span className="data-type">영</span><span className="data-type">→한 번역</span></h3>
												<div className="item-description-box">
													<p className="item-description">14,146 단어 기준</p>
													<span className="item-summary">(12pt / 1,630 line / 60 page)</span>
												</div>
											</div>
											<div className="item-content-box">
												<div className="item-content left-content">
													<span className="label body-sm">시중업체</span>
													<span className="other-price">100~140</span>
													<span className="body-sm">만원</span>
												</div>
												<div className="img-box">
													<img src="/images/service/icons/arrow-lg.png" alt="arrow"/>
												</div>
												<div className="item-content right-content">
													<span className="label body-lg">IP 킹콩</span>
													<span className="our-price body-lg">약 20</span>
													<span className="tag body-sm">만원</span>
												</div>
											</div>
										</div>
										<div className="item-text-box">
											<p className="item-description body-md">한→영 시 1페이지당 약 6,000원</p>
											<span className="item-caption body-sm">K-editor 1페이지 기준<br/>바탕체 12pt, 페이지당 20line</span>
										</div>
									</SwiperSlide>
									<SwiperSlide className="item" tag="div">
										<div className="item-wrapper">
											<div className="item-header">
												<h3 className="item-title body-lg">
													<span className="data-type">한</span>
													<span className="data-type">→중 번역</span>
												</h3>
												<div className="item-description-box">
													<p className="item-description">14,465 단어 기준</p>
													<span className="item-summary">(12pt / 631 line / 25 page)</span>
												</div>
											</div>
											<div className="item-content-box">
												<div className="item-content left-content">
													<span className="label body-sm">시중업체</span>
													<span className="other-price">35~45</span>
													<span className="body-sm">만원</span>
												</div>
												<div className="img-box">
													<img src="/images/service/icons/arrow-lg.png" alt="arrow"/>
												</div>
												<div className="item-content right-content">
													<span className="label body-lg">IP 킹콩</span>
													<span className="our-price body-lg">약 11</span>
													<span className="tag body-sm">만원</span>
												</div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-header">
												<h3 className="item-title body-lg">
													<span className="data-type">중</span>
													<span className="data-type">→한 번역</span>
												</h3>
												<div className="item-description-box">
													<p className="item-description">16,814 단어 기준</p>
													<span className="item-summary">(12pt / 600 line / 34 page)</span>
												</div>
											</div>
											<div className="item-content-box">
												<div className="item-content left-content">
													<span className="label body-sm">시중업체</span>
													<span className="other-price">40~50</span>
													<span className="body-sm">만원</span>
												</div>
												<div className="img-box">
													<img src="/images/service/icons/arrow-lg.png" alt="arrow"/>
												</div>
												<div className="item-content right-content">
													<span className="label body-lg">IP 킹콩</span>
													<span className="our-price body-lg">약 9</span>
													<span className="tag body-sm">만원</span>
												</div>
											</div>
										</div>
										<div className="item-text-box">
											<p className="item-description body-md">한→중 시 1페이지당 약 7,500원</p>
											<span className="item-caption body-sm">K-editor 1페이지 기준<br/>바탕체 12pt, 페이지당 20line</span>
										</div>
									</SwiperSlide>
									<SwiperSlide className="item" tag="div">
										<div className="item-wrapper">
											<div className="item-header">
												<h3 className="item-title body-lg">
													<span className="data-type">한</span>
													<span className="data-type">→일 번역</span>
												</h3>
												<div className="item-description-box">
													<p className="item-description">14,465 단어 기준</p>
													<span className="item-summary">(12pt / 631 line / 25 page)</span>
												</div>
											</div>
											<div className="item-content-box">
												<div className="item-content left-content">
													<span className="label body-sm">시중업체</span>
													<span className="other-price">70~80</span>
													<span className="body-sm">만원</span>
												</div>
												<div className="img-box">
													<img src="/images/service/icons/arrow-lg.png" alt="arrow"/>
												</div>
												<div className="item-content right-content">
													<span className="label body-lg">IP 킹콩</span>
													<span className="our-price body-lg">약 18</span>
													<span className="tag body-sm">만원</span>
												</div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-header">
												<h3 className="item-title body-lg">
													<span className="data-type">일</span>
													<span className="data-type">→한 번역</span>
												</h3>
												<div className="item-description-box">
													<p className="item-description">8,406 단어 기준</p>
													<span className="item-summary">(12pt / 631 line / 25 page)</span>
												</div>
											</div>
											<div className="item-content-box">
												<div className="item-content left-content">
													<span className="label body-sm">시중업체</span>
													<span className="other-price">40~50</span>
													<span className="body-sm">만원</span>
												</div>
												<div className="img-box">
													<img src="/images/service/icons/arrow-lg.png" alt="arrow"/>
												</div>
												<div className="item-content right-content">
													<span className="label body-lg">IP 킹콩</span>
													<span className="our-price body-lg">약 9</span>
													<span className="tag body-sm">만원</span>
												</div>
											</div>
										</div>
										<div className="item-text-box">
											<p className="item-description body-md">한→일 시 1페이지당 약 4,500원</p>
											<span className="item-caption body-sm">K-editor 1페이지 기준<br/>바탕체 12pt, 페이지당 20line</span>
										</div>
									</SwiperSlide>
									<div className="pagination-container"></div>
								</Swiper>
							)
						}
					</div>
				</section>
				<section id="section-process">
					<div className="container process-section">
						<div className="title-container">
							<div className="title-box">
								<h2 className="title title-md">명세서의 번역 예상금액이 궁금하신가요?</h2>
								<p className="description body-lg">IP킹콩에 가입하고 번역문을 올리면 {this.state.mode === 'sm' && <br/>}번역 예상 비용을 바로 확인하실 수 있어요!</p>
							</div>
						</div>
						<div className="item-container">
							<div className="arrow-box">
								<div className="img-box">
									<img src="/images/service/icons/arrow-rounded.png" alt="프로세스 화살표"/>
								</div>
								<div className="img-box">
									<img src="/images/service/icons/arrow-rounded.png" alt="프로세스 화살표"/>
								</div>
								<div className="img-box">
									<img src="/images/service/icons/arrow-rounded.png" alt="프로세스 화살표"/>
								</div>
							</div>
							<ul className="item-wrapper">
								<li className="item">
									<div className="item-header">
										<div className="img-box">
											<img src="/images/service/icons/process-icon-1.png" alt="번역언어 선택"/>
										</div>
									</div>
									<h3 className="item-title card-title-md">번역언어 선택</h3>
								</li>
								<li className="item">
									<div className="item-header">
										<div className="img-box">
											<img src="/images/service/icons/process-icon-2.png" alt="문서 업로드 (*.docx)"/>
										</div>
									</div>
									<h3 className="item-title card-title-md">문서 업로드 (*.docx)</h3>
								</li>
								<li className="item">
									<div className="item-header">
										<div className="img-box">
											<img src="/images/service/icons/process-icon-3.png" alt="번역상품 선택"/>
										</div>
									</div>
									<h3 className="item-title card-title-md">번역상품 선택</h3>
								</li>
								<li className="item">
									<div className="item-header">
										<div className="img-box">
											<img src="/images/service/icons/process-icon-4.png" alt="번역비용 확인"/>
										</div>
									</div>
									<h3 className="item-title card-title-md">번역비용 확인</h3>
								</li>
							</ul>
						</div>
						<div className="caption-box">
							<p className="caption body-md">또는, cs@ipkingkong.com로 번역문과 번역을 원하시는 언어를 알려주세요.<br/>IP KINGKONG 번역 속도만큼이나 빠르게 {this.state.mode === 'sm' && <br/>}번역 예상 비용을 회신드리겠습니다.</p>
						</div>
					</div>
				</section>
				<section id="section-faq">
					<div className="container faq-section">
						<div className="title-container">
							<div className="title-box">
								<h2 className="title title-md">자주묻는 질문</h2>
							</div>
						</div>
						<Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										캐시 충전을 하려면 어떻게 해야하나요?
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="item-content-box">
										<p className="description body-lg">① 아래 계좌로 충전할 캐시에 해당하는 금액을 입금하여주세요.<br/><span className="caption body-md">입금계좌 : KB 국민은행 084001-04-221802 / 입금자명 : ㈜워트인텔리전스</span></p>
										<p className="description body-lg">② cs@ipkingkong.com으로 입금일, 입금자명, 입금액, 연락처(핸드폰번호)를 알려주세요.<br/><span className="caption body-md">※ 지출증빙으로 현금영수증 또는 세금계산서가 필요하신 분께서는 아래 내용을 cs@ipkingkong.com으로 보내주시면, <br/>확인 후 빠르게 처리 도와드리겠습니다.<br/>현금영수증 : 충전일, 성함, 핸드폰번호 <br/>세금계산서 : 충전일, 사업자등록증 사본, 세금계산서 발행 이메일주소</span></p>
										<p className="description body-lg">③ 충전캐시에 따른 총 결제금액 및 추가캐시 안내</p>
										<div className="img-box">
											<img src={ this.state.mode === 'lg' ? '/images/service/table.webp' : '/images/service/table--mbl.webp' } style={{ width: '100%' }} alt="충전캐시에 따른 총 결제금액 및 추가캐시 안내"/>
										</div>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										번역을 이용하려면 꼭 캐시를 미리 충전해야 하나요?
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="item-content-box">
										<p className="description body-lg">결제 단계에서 카드 결제를 통해 바로 결제 후 번역 서비스를 이용하실 수도 있습니다. <br/>다만 캐시를 미리 충전하셔서 이용하시면 서비스 캐시를 함께 넣어드리고 있으니 소액이더라도 충전하셔서 이용하시는 것을 권해드리고 있습니다. ^^</p>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										지원되는 언어는 영어 ↔ 한국어 / 일어 ↔ 한국어 / 중국어 ↔ 한국어 외에 또 어떤 언어가 있나요?
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="item-content-box">
										<p className="description body-lg">네, 현재 지원되는 언어는 영어 ↔ 한국어 / 일어 ↔ 한국어 / 중국어 ↔ 한국어 가 가능합니다. <br/>그 외 언어도 서비스 준비를 위해 학습을 진행중이며, 준비가 완료되는대로 사이트에 공지드리겠습니다.</p>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										명세서 번역만 가능한가요?
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="item-content-box">
										<p className="description body-lg">보다 정확한 번역을 위해 현재 AI 번역 엔진은 명세서번역에 특화되어 있습니다. <br/>그 외 서비스도 준비중이니 조금만 기다려주세요!</p>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										서비스에 대해 더 알고 싶어요.
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="item-content-box">
										<p className="description body-lg">궁금하신 점이 있으시다면 cs@ipkingkong.com으로 궁금하신 점을 알려주세요. <br/>빠르게 확인하고 답변드릴 수 있도록 하겠습니다. ^^</p>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
						</Accordion>
					</div>
				</section>
				<section id="section-evidence">
					<div className="container evidence-section">
						<div className="title-container">
							<div className="title-box">
								<h2 className="title title-md">보도자료</h2>
							</div>
						</div>
						<ul className="item-container">
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-1.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md">특허전문 AI번역 플랫폼 ‘IP 킹콩’, 번역 품질·언어 대폭 업그레이드</h3>
									<p className="item-description body-md">특허 전문 인공신경망 NMT 번역과 휴먼보정을 통한 고품질 번역을 제공합니다...</p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.datanet.co.kr/news/articleView.html?idxno=179483" target="_blank">뉴스 자세히 알아보기</a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-2.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md">국내 최초 특허 전문 AI 번역서비스 ‘IP킹콩’ 출시</h3>
									<p className="item-description body-md">IP킹콩은 특허번호를 입력하거나 문서를 업로드하는 방식으로 번역을 의뢰하고 단 하루(최소 6시간)만에 번역문을 ...</p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="http://www.it-b.co.kr/news/articleView.html?idxno=31411" target="_blank">뉴스 자세히 알아보기</a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md">[AI 번역] 美 Apple, 제스처로 조정하는 “자율차 목적지 안내시...</h3>
									<p className="item-description body-md">apple이 제스처, 음성 등 탑승자가 의도하는 신호로 차량 이동의 최종 목적지를 미세하...</p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/09/20/06/24/36/22172/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-apple-%ec%a0%9c%ec%8a%a4%ec%b2%98%eb%a1%9c-%ec%a1%b0%ec%a0%95%ed%95%98%eb%8a%94-%ec%9e%90%ec%9c%a8%ec%b0%a8-%eb%aa%a9%ec%a0%81%ec%a7%80-%ec%95%88%eb%82%b4%ec%8b%9c/" target="_blank">뉴스 자세히 알아보기</a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md">[AI 번역] 美 디즈니, ‘테마파크 공간 내 가상 세계’ 특허</h3>
									<p className="item-description body-md">디즈니(Disney)가 놀이기구 표면에 이미지를 투사해 테마파크 공간에서 가상적인 느낌을...</p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/05/01/08/02/09/20129/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-%eb%94%94%ec%a6%88%eb%8b%88-%ed%85%8c%eb%a7%88%ed%8c%8c%ed%81%ac-%ea%b3%b5%ea%b0%84-%eb%82%b4-%ea%b0%80%ec%83%81-%ec%84%b8%ea%b3%84-%ed%8a%b9%ed%97%88/" target="_blank">뉴스 자세히 알아보기</a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md">[AI 번역] 현대차, ‘제자리 유턴’ 가능한 4륜 조향장치 특허</h3>
									<p className="item-description body-md">현대차가 4륜 차량의 인시츄 회전(in-situ rotation) 동작을 쉽고 간편하게 동작함으...</p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/08/14/11/52/01/21740/ai-%eb%b2%88%ec%97%ad-%ed%98%84%eb%8c%80%ec%b0%a8-%ec%a0%9c%ec%9e%90%eb%a6%ac-%ec%9c%a0%ed%84%b4%ec%9d%b4-%ea%b0%80%eb%8a%a5%ed%95%9c-4%eb%a5%9c-%ec%a1%b0%ed%96%a5%ec%9e%a5%ec%b9%98-%ed%8a%b9/" target="_blank">뉴스 자세히 알아보기</a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md">[AI 번역] 美 Apple, 광투과율 변하는 ‘가변 불투명 선루프’ 특허</h3>
									<p className="item-description body-md">apple이 자동차 사용자의 제어에 따라 투명도 수준을 변경할 수 있는 고급 선루프 시스...</p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/04/01/15/12/03/19529/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-apple-%ea%b4%91%ed%88%ac%ea%b3%bc%ec%9c%a8-%eb%b3%80%ed%95%98%eb%8a%94-%ea%b0%80%eb%b3%80-%eb%b6%88%ed%88%ac%eb%aa%85-%ec%84%a0%eb%a3%a8%ed%94%84-%ed%8a%b9/" target="_blank">뉴스 자세히 알아보기</a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md">[AI 번역] 美 디즈니, 놀이기구 모양을 바꾸는 ‘차량 리셋’ 특허</h3>
									<p className="item-description body-md">디즈니(Disney)가 놀이기구 탑승자에 따라 놀이 차량의 모양과 형태를 조정해 물리적 ...</p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/07/30/18/19/22/21599/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-%eb%94%94%ec%a6%88%eb%8b%88-%eb%86%80%ec%9d%b4%ea%b8%b0%ea%b5%ac-%eb%aa%a8%ec%96%91%ec%9d%84-%eb%b0%94%ea%be%b8%eb%8a%94-%ec%b0%a8%eb%9f%89-%eb%a6%ac%ec%85%8b/" target="_blank">뉴스 자세히 알아보기</a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md">[AI 번역] 美 Apple, 말리는 ‘롤러블 디스플레이’ 특허</h3>
									<p className="item-description body-md">apple이 디스플레이의 일부를 원형 롤러를 통해 내부에 말린 상태로 숨겨 확장 시 넓게 ...</p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/02/20/10/42/28/19223/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-apple-%eb%a7%90%eb%a6%ac%eb%8a%94-%eb%a1%a4%eb%9f%ac%eb%b8%94-%eb%94%94%ec%8a%a4%ed%94%8c%eb%a0%88%ec%9d%b4-%ed%8a%b9%ed%97%88/" target="_blank">뉴스 자세히 알아보기</a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md">[AI 번역] 美 메타(Meta), 증강현실을 활용한 ‘3D 대화’ 특허</h3>
									<p className="item-description body-md">meta가 온라인 대화를 증강현실을 통해 3D 영상으로 대화 참여자가 실제 나타나 직접 ...</p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/05/07/16/46/21/20279/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-%eb%a9%94%ed%83%80meta-%ea%b3%b5%ec%83%81-%ea%b3%bc%ed%95%99-%ec%86%8c%ec%84%a4%ec%9d%98-3d-%eb%8c%80%ed%99%94-%ed%8a%b9%ed%97%88/" target="_blank">뉴스 자세히 알아보기</a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md">[AI 번역] 美 이베이(eBay), 쇼핑몰 ‘자동 포스팅 시스템’ 특허</h3>
									<p className="item-description body-md">미국 전자상거래 기업 eBay가 판매자 대신 온라인에 제품을 게시하고 판매하는 시스템...</p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/02/18/11/01/59/19148/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-%ec%9d%b4%eb%b2%a0%ec%9d%b4ebay-%ec%9e%90%eb%8f%99-%ed%8f%ac%ec%8a%a4%ed%8c%85-%ec%8b%9c%ec%8a%a4%ed%85%9c-%ed%8a%b9%ed%97%88%ec%99%84/" target="_blank">뉴스 자세히 알아보기</a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md">[AI 번역] 美 메타(Meta), ‘가상 현실-현실 충돌 회피’ 특허</h3>
									<p className="item-description body-md">facebook에서 사명을 변경한 Meta가 인공 현실 시스템의 사용자가 현실의 물건과 충...</p></div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/04/16/21/07/12/19673/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-%eb%a9%94%ed%83%80meta-%ea%b0%80%ec%83%81-%ed%98%84%ec%8b%a4-%ed%98%84%ec%8b%a4-%ec%b6%a9%eb%8f%8c-%ed%9a%8c%ed%94%bc-%ed%8a%b9%ed%97%88/" target="_blank">뉴스 자세히 알아보기</a>
								</div>
							</li>
						</ul>
					</div>
				</section>
				{
					!this.props.isAuthenticated && (
						<section id='section-promotion'>
							<div className='container promotion-section'>
								<div className='title-container'>
									<div className='title-box'>
										<h2 className='title title-md'>직접 체험해보고 차별점을 확인하세요.</h2>
									</div>
									<div className='button-box'>
										<div className='button card-title-md'>
											<Link to="/join/form" className="btn-link" target="_blank">
												지금 회원가입하기
											</Link>
										</div>
									</div>
								</div>
							</div>
						</section>
					)
				}
			</div>
		);
	}
}
